@font-face {
  font-family: "Fira Code";
  src: local("Fira Code"),
    url("../fonts/FiraCode/FiraCode-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: "Fira Code";
  src: local("Fira Code"),
    url("../fonts/FiraCode/FiraCode-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: "Fira Code";
  src: local("Fira Code"),
    url("../fonts/FiraCode/FiraCode-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}
