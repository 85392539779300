@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

%buttonSettings {
  font-size: 14px;
  font-weight: 400;
  font-family: $fontRoboto;
}

.postsScreen_savedAlert.active {
  display: flex;
}

.ant-spin .ant-spin-dot-item {
  background-color: black;
}

.postsScreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  &_savedAlert {
    display: none;
    position: fixed;
    z-index: 9;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: $colorWhite;
    &_text {
      margin: 10px;
      font-family: $fontPoppins;
      font-size: 18px;
      color: black;
      font-weight: 500;
    }
  }

  &_topContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &_link {
      display: flex;
      margin: 10px 10px 5px 0;
    }
  }

  &_upper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto 0;
    &_btn {
      &_wrap {
        display: flex;
        height: 100%;
        margin-left: 13px;
      }
      &_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
      &_pdf {
        @extend %buttonSettings;
      }
      &_filter {
        @extend %buttonSettings;
        font-weight: 500;
      }
      &_select {
        @extend %buttonSettings;
        width: 120px;
        font-weight: 500;
      }
      &_drawing {
        @extend %buttonSettings;
      }
    }

    &_label {
      display: flex;
      height: 100%;
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
    }
  }

  &_bottom {
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;

    &_wrapper {
      width: min-content;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-content: space-between;
      justify-items: center;
      align-content: center;
      align-items: center;
    }

    &_post {
      width: 246px;
      margin: 25px 21px;
    }
  }
}

.marginedItem {
  margin: 0 25px;
}
