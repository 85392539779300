@import "../../../styles/variables.scss";

.fadeSlider {
  display: flex;
  width: 100%;
  &_box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &_title {
    padding: 0 100px;
    color: $colorGrey;
    font-family: $fontFiraCode;
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.15px;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  &_description {
    padding: 0 50px;
    color: $colorGrey;
    text-align: center;
    font-family: $fontFiraCode;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
}
