@import "../../../../styles/variables.scss";

%buttonSettings {
  font-size: 14px;
  font-weight: 400;
  font-family: $fontRoboto;
}

.gptModal {
  width: 500px;
  padding: 25px;
  border-radius: 15px;
  background-color: $backgroundColorGlobal;
  font-family: $fontFiraCode;

  &_logo {
    width: 100%;
    margin-bottom: 20px;
  }

  &_loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &_inProgress {
      font-weight: 800;
    }
    &_video {
      filter: brightness(4) grayscale(1) contrast(1.5);
      mix-blend-mode: color-burn;
    }
    &_description {
      text-align: center;
    }
  }

  &_result {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_response {
      font-weight: 800;
    }

    &_container {
      display: flex;
      gap: 10px;
    }

    &_text {
      width: 100%;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
      font-family: $fontFiraCode;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &_delete {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  &_confirm {
    &_text {
      margin-bottom: 20px;
    }

    &_container {

      display: flex;
      gap: 10px;
    }
  }

  &_btn {
    @extend %buttonSettings;

    min-width: 117px;
    width: 117px;
    background-color: transparent;
    border: 1px solid $colorBeige;
    color: $colorBeige;

    &:hover {
      opacity: 0.7;
    }

    &_delete {
      display: flex;
      align-items: center;
      background-color: $colorFontBlack;
      color: white;
      border: none;

      &_icon {
        display: flex;
        margin-right: 3px;
      }
    }
  }
}
