@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

$post-border-radius: 4.196px;
$post-box-shadow: 0px 1.67841px 3.35682px 0px rgba(0, 0, 0, 0.25);

%postMedia {
  width: 100%;
  height: 270px;
  border-radius: $post-border-radius $post-border-radius 0 0;
}

%media_error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(59, 59, 59);
}

%media_error_text {
  color: $backgroundColorGlobal;
  font-family: $fontFiraCode;
  text-align: center;
}

.post {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $post-box-shadow;
  border-radius: $post-border-radius;
  overflow: hidden;

  &_upper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    &_box {
      border-radius: $post-border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: white;
      &_img {
        @extend %postMedia;
        object-fit: cover;
      }
      &_video {
        @extend %postMedia;
        object-fit: contain;
      }
    }
    &_checkbox {
      display: flex;
      justify-content: flex-end;
      margin: 5px;
      width: 54.77px;
      position: absolute;
      cursor: pointer;
    }
    &_top {
      display: flex;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      width: 38px;
      height: 38px;
      border-bottom-right-radius: 5px;
      padding: 4px;
      left: 0;
      &_arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        filter: invert(
          100%
        ); // This will change the color of the image from black to white
        // rotate: 180deg;
        // width: 20px;
        // height: 20px;
      }
    }
    &_remove {
      display: flex;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 4px;
      position: absolute;
      cursor: pointer;
      border-bottom-left-radius: 5px;
    }
  }
  &_media {
    border-radius: 4.196px;
    &_error {
      height: 270px;
      @extend %media_error;
      &_text {
        @extend %media_error_text;
        font-size: 24px;
      }
    }
  }
  &_bottom {
    width: 100%;
    background-color: $lightYellow;
    padding: 9px 10.9px 0;
    border-bottom-left-radius: $post-border-radius;
    border-bottom-right-radius: $post-border-radius;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    letter-spacing: 0.336px;

    &_box {
      border-bottom-left-radius: $post-border-radius;
      border-bottom-right-radius: $post-border-radius;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $fontPoppins;
      margin-bottom: 9px;

      &_light {
        font-weight: 400;
      }

      &_bold {
        font-weight: 700;
      }
    }

    &_note {
      max-height: 130px;
      height: fit-content;
      margin-bottom: 9px;

      &_text {
        font-family: $fontFiraCode;
        font-size: 15px;
        word-wrap: break-word;
      }
    }
  }

  &_item {
    display: flex;
    align-items: center;

    &_description {
      letter-spacing: 0.7px;
    }

    &_icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
