@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.homeScreen {
  width: 485px;
  display: flex;
  justify-content: center;
  font-family: $fontFiraCode;

  &_loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_title {
      text-align: center;
      margin: 15px 0;
      color: var(--Black, #2f2f2f);
      text-align: center;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 0.15px;
    }

    &_description {
      text-align: center;
      color: var(--Black, #2f2f2f);
      text-align: center;
      font-family: Fira Code;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.15px;
    }

    &_video {
      filter: brightness(4) grayscale(1) contrast(1.5);
      mix-blend-mode: color-burn;
      margin: 16px 0 20px;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    width: 388px;
    padding: 0 24px;
    margin-top: 32px;
    @media (max-width: $mediaQueryMedium) {
      padding: 0 44px;
      margin-top: 24px;
    }
    &_url {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 87px;
      margin-bottom: 32px;
      border: 1px solid $colorBlack;
      border-radius: 48px;
      padding: 14px 38px 21px;
      background-color: white;
      @media (max-width: $mediaQueryMedium) {
        margin-bottom: 24px;
        height: 77px;
        padding: 14px 38px;
      }
      &_title {
        font-family: $fontRoboto;
        font-size: 12px;
        text-align: start;
        color: $colorGrey;
        font-weight: 500;
        line-height: 266%;
        letter-spacing: 1px;
        text-transform: uppercase;
        @media (max-width: $mediaQueryMedium) {
          line-height: 200%;
        }
      }
      &_input {
        color: $colorBlack;
        font-family: $fontRoboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%;
        letter-spacing: 0.17px;
        border: 0;
        padding: 0;
        &:hover {
          border: 0;
        }

        &:focus {
          border: 0;
          box-shadow: none;
        }
      }
    }
    &_datePicker {
      display: flex;
      width: 100%;
      height: 87px;
      margin-bottom: 32px;
      @media (max-width: $mediaQueryMedium) {
        margin-bottom: 24px;
        height: 77px;
      }
    }
    &_radio {
      display: flex;
      width: 100%;
      height: 55px;
      margin-bottom: 32px;
      border-radius: 48px;
      overflow: hidden;
      @media (max-width: $mediaQueryMedium) {
        margin-bottom: 24px;
      }
      &_btn {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
        border: 0;
        font-family: $fontFiraCode;
        font-size: 18px;
        font-weight: 500;
        padding: 0 2px;
      }
      &_group {
        display: flex;
        width: 100%;
      }
    }
    &_btn {
      height: 55px;
      &_back {
        background-color: $colorGrey;
      }
      &_audit {
        height: 55px;
        margin-bottom: 32px;
        @media (max-width: $mediaQueryMedium) {
          margin-bottom: 24px;
        }
      }
      &_link {
        text-decoration: none;
      }
    }
  }
}

.fadeSlider {
  display: flex;
  width: 100%;
  &_box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &_title {
    padding: 0 100px;
    color: $colorGrey;
    font-family: $fontFiraCode;
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.15px;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  &_description {
    padding: 0 50px;
    color: $colorGrey;
    text-align: center;
    font-family: $fontFiraCode;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
}

.ant-radio-group-solid :where(.ant-radio-button-wrapper:hover) {
  color: $colorBeige !important;
}

.ant-radio-group-solid
  :where(
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  ) {
  background-color: $colorBeige !important;
  color: $colorWhite !important;
  &:focus {
    background-color: $colorBeige !important;
    color: $colorWhite !important;
  }
  &:active {
    background-color: $colorBeige !important;
    color: $colorWhite !important;
  }
  &:hover {
    background-color: $colorBeige !important;
    color: $colorWhite !important;
    &::before {
      background-color: $colorBeige !important;
    }
  }
  &::before {
    background-color: $colorBeige !important;
  }
}
