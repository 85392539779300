@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

%flexColumn {
  display: flex;
  width: 100%;
  flex-direction: column;
}

%oauthButton {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: $fontRoboto;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: $mediaQueryMedium) {
    padding: 10px;

    font-size: 16px;
  }
}

%formButton {
  width: 100%;
  border-radius: 12px;
  color: white;
  border: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  height: 52px;
  align-items: center;
  font-family: $fontRoboto;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: 0.2px;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
  }
}

%formInput {
  border-radius: 12px;
  border: 1px solid white;
  height: 48px;
  padding: 16px;
  margin-bottom: 24px;
  color: $colorGrey;
  font-family: $fontRoboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
  &:focus {
    outline: 0;
  }
  @media (max-width: $mediaQueryMedium) {
    margin-bottom: 18px;
  }
}

%formText {
  color: $colorBlack;
  font-family: $fontRoboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.16px;
  margin-bottom: 8px;
}

.ant-input {
  font-family: $fontRoboto;
}

.auth {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: $mediaQueryMedium) {
    margin-top: 20px;
  }
  &_container {
    @extend %flexColumn;
    width: 388px;
    @media (max-width: $mediaQueryMedium) {
      width: 320px;
    }
  }

  &_form {
    @extend %flexColumn;
    &_email {
      @extend %flexColumn;
      &_text {
        @extend %formText;
      }
      &_input {
        @extend %formInput;
      }
    }
    &_password {
      @extend %flexColumn;
      &_text {
        @extend %formText;
      }
      &_input {
        @extend %formInput;
        padding: 2px 16px 0;
      }

      &_forgot {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        &_link {
          color: $colorBeige;
          text-align: center;
          font-family: $fontRoboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.16px;
          cursor: pointer;
          transition: 0.2s;
          text-decoration: none;
          &:hover {
            color: $colorGrey;
          }
        }
      }
    }

    &_btn {
      &_box {
        @extend %flexColumn;
        justify-content: center;
        margin: 24px 0 40px;
        @media (max-width: $mediaQueryMedium) {
          margin: 18px 0 20px;
        }
      }

      &_link {
        text-decoration: none;
        margin-top: 24px;
        @media (max-width: $mediaQueryMedium) {
          margin-top: 18px;
        }
      }
      &_login {
        @extend %formButton;
        background: $colorBeige;
      }
      &_back {
        @extend %formButton;
        background: $colorGrey;
      }
    }
  }

  &_or {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-bottom: 24px;
    @media (max-width: $mediaQueryMedium) {
      margin-bottom: 18px;
    }

    &_line {
      width: 100%;
      height: 1px;
      background-color: $colorLightGrey;
      border: 0px;
    }

    &_text {
      color: $colorLightBlack;
      text-align: center;
      margin: 0 16px;

      font-family: $fontRoboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
      letter-spacing: 0.15px;
    }
  }

  &_oauth {
    width: 100%;
    margin-bottom: 40px;
    @media (max-width: $mediaQueryMedium) {
      margin-bottom: 20px;
    }

    &_icon {
      margin-right: 15px;
      display: flex;
      align-items: center;
      @media (max-width: $mediaQueryMedium) {
        margin-right: 10px;
      }
    }

    &_facebook {
      background-color: $colorBlue;
      color: $colorWhite;
      margin-bottom: 24px;
      font-weight: 500;
      @extend %oauthButton;
    }

    &_google {
      background-color: $colorWhite;
      color: $colorTransparentGrey;
      font-weight: 500;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17),
        0px 0px 3px 0px rgba(0, 0, 0, 0.08);
      @extend %oauthButton;
    }
  }

  &_signUp {
    margin: auto;
    &_link {
      text-decoration: none;
      color: #1e4ae9;
      cursor: pointer;
    }
  }
}
