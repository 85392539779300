@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

%flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%buttonSettings {
  width: 340px;
  height: 55px;
  @media (max-width: $mediaQueryMedium) {
    width: 260px;
    height: 55px;
  }
}

.selectScreen {
  margin-top: 32px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  font-family: $fontFiraCode;
  @media (max-width: $mediaQueryMedium) {
    margin-top: 24px;
  }
  @extend %flex;
  &_button {
    @extend %buttonSettings;
    &_projects {
      background-color: $colorGrey;
    }
  }
}

.margined {
  margin-bottom: 32px;
  @media (max-width: $mediaQueryMedium) {
    margin-bottom: 24px;
  }
}
