//fonts
$fontFiraCode: "Fira Code";
$fontPoppins: "Poppins";
$fontRoboto: "Roboto";

//colors
$backgroundColorGlobal: #f7f2ed;
$colorBeige: #aa9165;
$colorDarkBegie: #ae9060;
$colorBlack: #0c1421;
$colorGrey: #2f2f2f;
$colorLightBlack: #294957;
$colorLightGrey: #cfdfe2;
$colorTransparentGrey: #0000008a;
$colorBlue: #1877f2;
$colorWhite: #ffffff;
$colorFontBlack: #000;
$lightYellow: #f6f2ee;
$colorGreen: limegreen;

//border
$borderMain: 1px solid #aa9165;
$post-border-radius: 4.196px;
$post-box-shadow: 0px 1.67841px 3.35682px 0px rgba(0, 0, 0, 0.25);

//media queries
$mediaQueryMedium: 1650px;
