@import "../../styles/variables.scss";

%formButton {
  width: 100%;
  height: 100%;
  border: 0;
  color: $colorWhite;
  text-align: center;
  font-family: $fontFiraCode;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 21px;
  border-radius: 48px;
  cursor: pointer;
  letter-spacing: 0.2px;
  transition: 0.2s;
  word-wrap: break-word;
  &:hover {
    opacity: 0.9;
  }
}

.share {
  &_btn {
    &_link {
      text-decoration: none;
      width: 100%;
      height: 100%;
    }
    &_primary {
      @extend %formButton;
      background-color: $colorBeige;
      color: $colorWhite;
    }

    &_secondary {
      @extend %formButton;
      background-color: transparent;
      border: 1px solid $colorBlack;
      color: $colorBlack;
      transition: 0.3s;
    }
    &_disabled {
      @extend %formButton;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
}
