@import "../../../../styles/variables.scss";

%media_error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(59, 59, 59);
}

%media_error_text {
  color: $backgroundColorGlobal;
  font-family: $fontFiraCode;
  text-align: center;
}

.projectPost {
  width: 270px;
  &_upper {
    display: flex;
    width: 100%;
    &_error {
      @extend %media_error;
      width: 100%;
      height: 280px;
      &_text {
        @extend %media_error_text;
        font-size: 24px;
      }
    }
    &_img {
      width: 100%;
      height: 280px;
      object-fit: cover;
    }
    &_video {
      width: 100%;
      height: 280px;
      object-fit: contain;
    }
  }

  &_bottom {
    width: 100%;
    display: flex;
    height: 130px;
    background-color: $lightYellow;
    border-bottom-left-radius: $post-border-radius;
    border-bottom-right-radius: $post-border-radius;

    &_note {
      width: 100%;
      font-family: $fontFiraCode;
      font-size: 18px;
      padding: 20px 10px;
      word-wrap: break-word;
    }
  }
}
