@import "../../styles/variables.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;

  &_close {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    &_btn {
      position: absolute;
      border: $borderMain;
      top: -40px;
      right: -40px;
      background-color: $colorBeige;
      opacity: 0.8;
    }
  }
}

.ant-btn-primary:not(:disabled){
  &:hover {
    opacity: 0.9;
    background-color: $colorBeige;
  }
  &:active {
    opacity: 0.9;
    background-color: $colorBeige;
  }
}

.modal.active {
  pointer-events: all;
  opacity: 1;
}
