@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

%fullscreenFlex {
  width: 100%;
  display: flex;
  justify-content: center;
}

%buttonSettings {
  font-size: 14px;
  font-weight: 400;
  font-family: $fontRoboto;
  border-radius: 48px;

  &:hover {
    opacity: 0.9;
  }
}

%buttonSize {
  width: 180px;
  height: 50px;
}

%box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: white;
}
%title {
  font-family: $fontFiraCode !important;
  font-size: 24px;
  font-weight: 500;
  padding: 20px 0 5px;
}
%input {
  position: relative;
  text-align: start;
  background-color: transparent;
  font-family: $fontFiraCode !important;
  font-weight: 400;
  font-size: 20px;
  padding: 0 10px 0 5px;
  overflow-y: hidden;
}
%info {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 10px;
}

.projectNotes {
  &_gpt {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 40px;

    &_button {
      @extend %buttonSettings;
      @extend %buttonSize;
      background-color: transparent;
      border: 1px solid $colorBeige;
      color: $colorBeige;

      &_disabled {
        display: none;
      }

      &:hover {
        background-color: $colorBeige;
        color: white;
      }
    }
  }

  &_body {
    @extend %fullscreenFlex;
    flex-direction: column;

    &_box {
      @extend %fullscreenFlex;
      align-items: flex-start;
      gap: 20px;
    }

    &_content {
      width: 100%;
    }

    &_nav {
      position: sticky;
      top: 0;

      &_content {
        display: flex;
        flex-direction: column;
        margin-top: 42px;
        gap: 10px;
      }

      &_link {
        @extend %buttonSettings;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 150px;
        height: 45px;
        padding: 10px 21px;
        background-color: transparent;
        border: 1px solid $colorBeige;
        color: $colorBeige;
        border-radius: 48px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background-color: $colorBeige;
          color: white;
        }
        &_disabled {
          display: none;
        }
      }
    }
  }

  &_notes {
    &_loader {
      height: 200px;
      display: flex;
      align-items: center;
    }
    &_notFound {
      font-family: $fontFiraCode;
      font-size: 24px;
      margin: auto;
      margin-top: 100px;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 30px auto;
    }
    &_container {
      margin: 34px 0;
      width: min-content;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      justify-items: center;
      row-gap: 34px;
      column-gap: 40px;
      align-content: center;
      align-items: flex-start;
    }
  }

  &_response {
    display: flex;
    width: 100%;
    flex-direction: column;
    &_disabled {
      display: none;
    }
    &_box {
      @extend %box;
      align-items: flex-start;
      padding: 10px 15px;
    }
    &_title {
      @extend %title;
      padding: 0;
    }
    &_input {
      @extend %input;
    }
    &_upper {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 20px;
    }
    &_button {
      @extend %buttonSettings;
      margin-top: 20px;
      width: 140px;
      height: 40px;
      background-color: transparent;
      border: 1px solid $colorFontBlack;
      color: $colorFontBlack;

      &_recreate {
        margin: 0;
        border: 1px solid $colorBeige;
        color: $colorBeige;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &_plan {
    display: flex;
    width: 100%;
    flex-direction: column;

    &_box {
      @extend %box;
    }

    &_comments {
      width: 100%;
      display: flex;
      flex-direction: column;

      &_btnsBlock {
        display: flex;
        flex-direction: column;
        &_button {
          @extend %buttonSettings;
          margin-bottom: 10px;
          width: 140px;
          height: 40px;
          background-color: transparent;
          border: 1px solid $colorBeige;
          color: $colorBeige;

          &:hover {
            background-color: $colorBeige;
            color: white;
          }
        }
      }

      &_icon {
        cursor: default;
        background-color: rgba(170, 145, 101, 0.8);
        border-radius: 100%;
      }

      &_container {
        display: flex;
        width: 100%;
        padding: 15px 10px;
      }

      &_box {
        margin-left: 5px;
        width: 100%;
      }

      &_text {
        font-family: $fontFiraCode;
        word-break: break-all;
      }

      &_author {
        font-family: $fontRoboto;
      }

      &_item .ant-comment-inner {
        padding-top: 0 !important;
        cursor: default !important;
        .ant-comment-avatar {
          cursor: default !important;
        }
      }
    }

    &_divider {
      @extend %title;
      cursor: default;
    }

    &_title {
      @extend %title;
      width: max-content;
      cursor: pointer;

      &_active {
        @extend %title;
        cursor: pointer;
        font-weight: 700;
      }

      &_stars {
        margin-top: 20px;
      }

      &_text {
        font-family: $fontFiraCode;
        margin-bottom: 20px;
      }
    }

    &_input {
      @extend %input;

      &_recreate {
        @extend %input;
        padding: 0;
      }

      &_gpt {
        @extend %input;
        margin-top: 25px;
      }

      &_container {
        width: 100%;
      }
    }

    &_info {
      @extend %info;
    }

    &_numbering {
      height: 100%;
      display: flex;
      text-align: center;
      font-size: 20px;
      padding-top: 1.8px;
      font-family: $fontFiraCode;
    }

    &_button {
      @extend %buttonSettings;
      width: 140px;
      height: 40px;
      background-color: transparent;
      border: 1px solid $colorBeige;
      color: $colorBeige;

      &:hover {
        background-color: $colorBeige;
        color: white;
      }
    }
  }
}
