@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

%fullscreenFlex {
  width: 100%;
  display: flex;
  justify-content: center;
}

%buttonSettings {
  font-size: 14px;
  font-weight: 400;
  font-family: $fontRoboto;
  border-radius: 48px;

  &:hover {
    opacity: 0.9;
  }
}

%buttonSize {
  width: 180px;
  height: 50px;
}

%menuFont {
  font-family: $fontPoppins;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 800;
  color: black;
  text-align: center;
}

%box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: white;
}
%title {
  font-family: $fontFiraCode !important;
  font-size: 24px;
  font-weight: 500;
  padding: 20px 0 5px;
}
%input {
  position: relative;
  text-align: start;
  background-color: transparent;
  font-family: $fontFiraCode !important;
  font-weight: 400;
  font-size: 20px;
  padding: 0 10px 0 5px;
  overflow-y: hidden;
}
%info {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 10px;
}

.detailedProject {
  @extend %fullscreenFlex;
  align-items: center;
  flex-direction: column;

  &_body {
    @extend %fullscreenFlex;
    flex-direction: column;

    
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    padding-bottom: 20px;
  }
  &_upper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &_btn {
      margin-left: 13px;
      &_back {
        @extend %buttonSettings;
        background-color: $colorBlack;
      }
      &_pdf {
        @extend %buttonSettings;
        border: 1px solid $colorBeige;
        color: $colorBeige;
      }
      &_selected {
        @extend %buttonSettings;
        border: 1px solid $colorBeige;
        color: $colorBeige;
      }
      &_unselected {
        @extend %buttonSettings;
      }
      &_wrap {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    &_menuBlock {
      display: flex;
      width: 100%;
      height: 50px;

      &_item {
        margin-left: 15px;
      }
      &_menu {
        width: 265px;
        height: 60px;
        padding: 15px 0;
        border: $borderMain;
        border-radius: 30px;
        color: black;
        background-color: $colorBeige;
      }
      &_tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 265px;
        height: 60px;
        padding: 10px 50px;
        font-size: 18px;
        background-color: $colorBeige;
        font-family: $fontPoppins;
        font-weight: 500;
        border: $borderMain;
        border-radius: 30px;
        cursor: pointer;
        transition: 0.2s;
        margin-right: 15px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &_notes {
    &_container {
      margin: 34px 0;
      width: min-content;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: space-between;
      justify-items: center;
      row-gap: 34px;
      column-gap: 40px;
      align-content: center;
      align-items: flex-start;
    }
  }
}

.detailedProject_upper_menuBlock_tab.active {
  background-color: white;
  color: black;
}
