@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
$mainPadding: 32px;

%flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auditLayout {
  @extend %flexColumn;
  background-color: white;
  &_container {
    @extend %flexColumn;
    margin-top: 60px;
  }
  &_title {
    color: $colorGrey;
    text-align: center;
    font-family: $fontRoboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    text-transform: uppercase;
    letter-spacing: 52.15px;
    margin: 65px 0;
    margin-right: -52.15px;
  }

  &_img {
    width: 380px;
    object-fit: contain;
  }
}
