@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.userAccount {
  &_logout {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &_acc {
      display: flex;
      margin-right: 10px;
    }
    &_btn {
      color: $colorBeige;
      border: 1px solid $colorBeige;
      font-family: $fontFiraCode;
      font-weight: 500;
      font-size: 16px;
      height: 100%;
      padding: 0 10px;
      border-radius: 48px;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
