@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
$mainPadding: 32px;

%flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectLayout {
  background-color: #f7f2ed;
  width: 100%;
  display: flex;
  position: relative;
  padding: $mainPadding;
  @media (max-width: $mediaQueryMedium) {
    justify-content: center;
  }
  &_poster {
    height: calc(100vh - $mainPadding * 2);
    min-height: 650px;
  }

  &_account {
    display: flex;
    position: absolute;
    right: 3%;
    top: 3%;
  }

  &_container {
    width: 388px;
    @extend %flexColumn;
  }

  &_column {
    @extend %flexColumn;
    justify-content: center;
    margin: auto;
  }

  &_img {
    object-fit: contain;
    width: 100%;
    @media (max-width: $mediaQueryMedium) {
      width: 320px;
    }
    &_link {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      @media (max-width: $mediaQueryMedium) {
        margin-bottom: 20px;
      }
    }
  }

  &_description {
    &_text {
      color: #313957;
      text-align: center;
      font-family: $fontRoboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
      letter-spacing: 0.15px;
    }
  }

  &_title {
    display: flex;

    padding: 0 34px;

    &_text {
      color: #313957;
      text-align: center;

      font-family: $fontRoboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      letter-spacing: 0.15px;
    }
  }

  &_privacy {
    text-decoration: none;
    margin-top: 32px;
    @media (max-width: $mediaQueryMedium) {
      margin-top: 24px;
    }
    &_link {
      color: $colorFontBlack;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        color: $colorBeige;
      }
    }
  }
}
