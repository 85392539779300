@import "../../styles/variables.scss";

%label {
  display: flex;
  padding: 5px 20px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  border: $borderMain;
  background-color: transparent;
  border-radius: 30px;
}

.label {
  @extend %label;

  &_project {
    @extend %label;
    transition: 0.2s all;
    background-color: white;
    border: 1px solid $colorBlack;
  }

  &_title {
    letter-spacing: 0.108px;
    font-family: $fontRoboto;
    color: $colorBeige;
    font-weight: 500;
    font-size: 16px;
    &_project {
      letter-spacing: 0.108px;
      font-family: $fontRoboto;
      color: $colorBeige;
      font-weight: 400;
      font-size: 16px;
      color: black;
    }
  }
  &_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
  }
  &_image {
    object-fit: contain;
    width: 20px;
    &_project {
      border-radius: 100px;
      mix-blend-mode: darken;
    }
  }
}

.hoverable {
  &:hover {
    background-color: #dcd2c8;
  }
}
