@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

$post-border-radius: 4.196px;
$post-box-shadow: 0px 1.67841px 3.35682px 0px rgba(0, 0, 0, 0.25);
$mediaHeight: 26vw;

%postMedia {
  width: 100%;
  height: 270px;
  border-radius: $post-border-radius $post-border-radius 0 0;
}

.slider {
  width: 65vw;
  background-color: $colorWhite;
  border-radius: 3.843px;
  padding: 0 71px;

  &_next_arrow {
    @extend %slider_arrow;
    right: 35px;
  }
  &_prev_arrow {
    @extend %slider_arrow;
    left: 35px;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 30px 1px 0;
    padding: 0 3px;
    width: 100%;
    height: 100%;
  }
  &_upper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 3.843px;
    margin-bottom: 0px;

    &_box {
      display: flex;
      justify-content: center;
      position: relative;
      width: 50%;
      height: 32vw;
    }
  }
  &_bottom {
    width: 100%;
    border-radius: 3.843px;
    margin: 15px 0;
    &_box {
      display: flex;

      height: 60px;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
    }

    &_label {
      display: flex;
      width: max-content;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &_post {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_link {
        text-decoration: none;
      }
      &_wrap {
        width: 160px;
        height: 50px;
      }
      &_btn {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        padding: 5px 0;
        span {
          text-align: center;
          letter-spacing: 1.3px;
          font-size: 14px;
        }
      }
    }
  }
  &_media {
    width: 100%;
    height: $mediaHeight;
    object-fit: contain;

    &_error {
      @extend %media_error;
      height: 100%;
      &_text {
        @extend %media_error_text;
        font-size: 30px;
      }
    }
  }
  &_plan {
    &_add {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 800;
      font-size: 17px;
      font-family: $fontFiraCode;
      width: 45%;
    }
    &_title {
      display: flex;
      margin-right: 70px;
      font-size: 16px;
      font-weight: 500;
    }
    &_check {
      position: relative;
      width: 51.55px;
      height: 51.55px;
    }
  }
  &_notes {
    &_container {
      display: flex;
      width: 45%;
      flex-direction: column;
    }

    &_postInfo {
      display: flex;
      margin-bottom: 10px;
      width: 100%;
      font-family: $fontPoppins;

      &_container {
        display: flex;
        max-width: 290px;
      }

      &_wrap {
        display: flex;
        width: 100%;
      }

      &_items {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &_avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 10px;
      }

      &_username {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin-right: 5px;
      }
      &_collab {
        font-size: 12px;
        font-weight: 600;
        color: $colorFontBlack;
        margin-right: 5px;
      }

      &_music {
        display: flex;
        row-gap: 2px;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
        color: $colorFontBlack;

        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      &_top {
        display: flex;
        width: 38px;
        height: 38px;
        &_arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: contain;
        }
      }
    }

    &_info {
      &_box {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 7.686px 12.298px;
        border: 0.769px solid var(--Gray, #d9d9d9);
        border-radius: 3.843px;
      }
      &_text {
        height: 100% !important;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        font-family: $fontFiraCode;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.115px;
      }
    }
  }
}

.slider_carousel {
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;

  &_media {
    width: 100%;
    object-fit: contain;
    height: $mediaHeight;
    margin-bottom: -4px;
    &_tiktok {
      width: 100%;
      object-fit: contain;
      height: 26.5vw;
      margin-bottom: -4px;
    }
  }

  &_box {
    &_tiktok {
      background-color: #f7f2ed;
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 99;
    }
  }

  &_audio {
    height: 30px;
    border-radius: 0;
    background-color: transparent;
    &::-webkit-media-controls-panel {
      background-color: #f7f2ed;
    }
  }
}

.slick-dots li button:before {
  @extend %slick-dots;
}

.slick-dots li button {
  @extend %slick-dots;
}

.slick-dots li {
  @extend %slick-dots;
}

.slick-dots li.slick-active button:before {
  @extend %slick-dots;
}

%slider_arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: max-content;
  height: max-content;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  background: transparent;
}

%slider_arrow_before {
  color: $colorBeige;
  font-size: 100px;
  font-family: $fontPoppins;
}

%slick-dots {
  color: white !important;
  height: 10px !important;
}

%media_error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(59, 59, 59);
}

%media_error_text {
  color: $backgroundColorGlobal;
  font-family: $fontFiraCode;
  text-align: center;
}
