.checkbox {
  $block: &;
  
  
  cursor: pointer;
  display: flex;
  align-items: center;
  
  
  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 0;
    border-radius: 10px;

    &:checked + #{$block}__icon .tick {
      stroke-dashoffset: 0;
    }
  }
  
  
  &__icon {
    width: 100%;
    flex-shrink: 0;
    overflow: visible;
    
    
    .tick {
      stroke-dasharray: 30px;
      stroke-dashoffset: 30px;
      transition: stroke-dashoffset 0.2s ease-out;
    }
  }
  
  
  &__label {
    margin-left: 0.5em;
  }
}