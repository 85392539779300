@import "../../styles/variables.scss";

.datePicker.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
}

.datePicker_title.disabled {
  line-height: 100%;
  color: rgba(0, 0, 0, 0.25);
}

.datePicker {
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  border: 1px solid $colorBlack;

  padding: 14px 38px 21px;

  &_title {
    font-family: $fontRoboto;
    font-size: 12px;
    text-align: start;
    color: $colorGrey;
    font-weight: 500;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (max-width: $mediaQueryMedium) {
      line-height: 200%;
    }
  }
}

.ant-picker .ant-picker-input input {
  cursor: pointer;
  font-family: $fontRoboto;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  display: inline-flex;
  width: 100%;
  text-align: start;
  color: $colorGrey;
}

.ant {
  &-space {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &_body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    &_disabled {
      background-color: black;
    }
  }
}
