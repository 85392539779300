@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.circle {
  border-radius: 100%;
}

.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  &_public {
    &_column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &_box {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
    }
    &_wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &_img {
      width: 200px;
      object-fit: contain;
    }
    &_btn {
      width: 200px;
      height: 45px;
      border: 1px solid $colorBeige;
      color: $colorBeige;
      line-height: 20px;
      font-size: 16px;
    }
    &_link {
      margin-top: 20px;
    }
    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 50px;
      padding: 10px 0px;
      font-size: 18px;
      background-color: $colorBeige;
      color: $colorWhite;
      font-family: $fontFiraCode;
      font-weight: 400;
      border: $borderMain;
      border-radius: 30px;
    }
  }
  &_acc {
    &_item {
      display: flex;
      width: 100%;
    }
    &_loader {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 150px;
    }
    &_container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_wrapper {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
    &_column {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 15px;
    }
    &_img {
      width: 70px;
      object-fit: contain;
      margin-bottom: 15px;
    }

    &_link {
      display: flex;
      color: black;
      text-decoration: none;
      height: min-content;
      margin: 15px 0;
      width: 100%;
    }
    &_soon {
      margin-top: 25px;
      font-family: $fontFiraCode;
      font-size: 18px;
    }
  }
}
